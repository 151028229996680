import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container } from '@mantine/core';
import { FC } from 'react';

interface PaginationProps {
  onPrevious: () => void;
  onNext: () => void;
  currentPage: number;
}
export const SubmissionPagination: FC<PaginationProps> = ({ currentPage, onNext, onPrevious }) => {
  const onHandleNext = () => {
    onNext();
  };

  const onHandlePrevious = () => {
    onPrevious();
  };
  return (
    <Container
      sx={{
        marginTop: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button onClick={() => onHandlePrevious()} type="button" variant="outline">
        <FontAwesomeIcon icon={faChevronLeft} size="lg" />
      </Button>
      <span
        style={{
          //  border: '1px solid',
          fontSize: '1.125rem', // Equivalent to text-lg
          height: '2rem', // Equivalent to h-11
          textAlign: 'center',
          padding: '0 0.75rem', // py-2 px-3
          display: 'inline-flex', // Ensures proper alignment
          alignItems: 'center', // Centers content vertically
          justifyContent: 'center', // Centers content horizontally
        }}
      >
        {currentPage}
      </span>
      <Button onClick={() => onHandleNext()} type="button" variant="outline">
        <FontAwesomeIcon icon={faChevronRight} size="lg" />
      </Button>
    </Container>
  );
};
