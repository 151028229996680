export enum SubmissionStatus {
  DRAFTED = 'DRAFTED',
  SUBMITTED = 'SUBMITTED',
}

export interface ContactDetails {
  firstName: string;
  lastName: string;
  company?: string;
  position?: string;
  email: string;
  phone?: string;
  relationship: 'INDIVIDUAL' | 'PARTNER' | 'DIRECTOR' | 'TRUSTEE' | 'AGENT' | string;
}

export interface GroupMember {
  memberType: string;
  legalEntity: string;
  beneficiary?: string;
  abnAcn?: string;
  tradingAccount: string;
}

export interface Address {
  postCode?: string;
  country?: string;
  state?: string;
  suburban?: string;
  address?: string;
}

export interface TradingDetails {
  openingBalance?: string;
}

export interface SubmissionSchema {
  contactDetails: ContactDetails;
  groupMember: GroupMember;
  address: Address;
  tradingDetails: TradingDetails;
}

export interface SubmissionSchemaV2 {
  contactDetails: ContactDetails;
  memberType: string;
  legalEntity: string;
  beneficiary?: string;
  abnAcn?: string;
  tradingAccount: string;
  address: Address;
  openingBalance?: string;
}
export interface TradeData {
  transactionDate: string;
  transactionType: string;
  quantity: string;
  sharePrice: string;
  totalAmount: string;
  subType: string;
}

export enum MemberType {
  GroupMember = 'GROUP_MEMBER',
  Director = 'DIRECTOR',
  ExecutorEstate = 'EXECUTOR_ESTATE',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
  SolicitorActing = 'SOLICITOR_ACTING',
}

export interface OptOutPayload {
  fullName: string;
  memberType: MemberType;
  legalEntity: string;
  email: string;
  address: Address;
}

export enum GroupMemberType {
  RETAIL_INVESTOR = 'Retail Investor',
  INSTITUTIONAL_INVESTOR = 'Institutional Investor',
  RETAIL_INVESTOR_AGENT = 'Retail Investor Agent',
}

export const GroupMemberTypeText = (member: string) => {
  switch (member) {
    case 'RETAIL_INVESTOR':
      return 'Retail Investor';
    case 'INSTITUTIONAL_INVESTOR':
      return 'Institutional Investor';
    case 'RETAIL_INVESTOR_AGENT':
      return 'Retail Investor Agent';
    default:
      return '';
  }
};
