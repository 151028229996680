import { Button, Group, Modal, Select, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { iagRegistrationSchema } from '../../pages/register/schema';
import { Member, useTeamMembersQuery } from '../hooks/useTeamMembersQuery';
import { useUpdateMember } from '../hooks/useUpdateMember';
import { InferType } from 'yup';
import { useEffect } from 'react';

interface EditTeamProps {
  isOpen: boolean;
  onClose: () => void;
  userDetail: Member;
}
export const EditTeam = ({ isOpen, onClose, userDetail }: EditTeamProps) => {
  const { getInputProps, onSubmit, reset } = useForm({
    initialValues: {
      email: userDetail.email,
      firstName: userDetail.firstName,
      lastName: userDetail.lastName,
    },
    validate: yupResolver(iagRegistrationSchema),
  });
  const { mutate, isSuccess } = useUpdateMember();
  const { refetch } = useTeamMembersQuery();

  const onHandleSubmit = (data: InferType<typeof iagRegistrationSchema>) => {
    mutate({
      id: userDetail.id,
      member: {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      onClose();
      refetch();
    }
  }, [isSuccess]);

  return (
    <Modal closeOnClickOutside={false} title="Edit Team" opened={isOpen} closeOnEscape onClose={onClose}>
      <form
        style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
        onSubmit={onSubmit(value => onHandleSubmit(value))}
      >
        <TextInput type="email" {...getInputProps('email')} label="Email" />
        <TextInput type="text" {...getInputProps('firstName')} label="First Name" />
        <TextInput type="text" {...getInputProps('lastName')} label="Last Name" />
        <Group position="center" mt={10}>
          <Button variant="outline" color="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="outline" type="submit">
            Save Edits
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
