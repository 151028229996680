import CoreConfig from '../../config/core';
import { IagRegister } from '../../pages/register/types';
import CoreService from '../../services/core';
import CatchError from '../../services/error';
import { ICoreServiceProps } from '../../services/types';
import { SupportingDocPayload } from '../hooks/useUploadSupportingDocs';
import { OptOutPayload, SubmissionSchemaV2, TradeData } from '../types';

class IagService extends CoreService {
  constructor(config: ICoreServiceProps) {
    super('/', config);
  }

  async Logout() {
    try {
      return this.axios.post(`/iag/auth/logout`);
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async GetProfile() {
    try {
      return this.axios.get(`/iag/account/me`);
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async GetMembers() {
    try {
      return this.axios.get(`/iag/admin/team`);
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async UpdateMember(member: IagRegister, id: string) {
    try {
      return this.axios.put(`/iag/admin/team/${id}`, member);
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async InitSubmission() {
    try {
      return this.axios.post(`/iag/submissions`);
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async GetSubmissionById(submissionId: string | undefined) {
    try {
      return this.axios.get(`/iag/submissions/${submissionId}`);
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async GetSubmission() {
    try {
      return this.axios.get(`/iag/submissions`);
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async UpdateSubmission(submissionId: string, payload: SubmissionSchemaV2) {
    try {
      return this.axios.put(`/iag/submissions/${submissionId}`, payload);
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async CreateTradeTransaction(submissionId: string, payload: TradeData) {
    try {
      return this.axios.post(`/iag/submissions/${submissionId}/trade`, payload);
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async GetTradeTransactions(submissionId: string) {
    try {
      return this.axios.get(`/iag/submissions/${submissionId}/trade`);
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async DeleteTradeTransaction(tradeId: string, submissionId: string) {
    try {
      return this.axios.delete(`/iag/submissions/${submissionId}/trade/${tradeId}`);
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async AdminGetSubmissions(query: string) {
    try {
      return this.axios.get(`/iag/admin/submissions?${query}`);
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async AdminGetOverview() {
    try {
      return this.axios.get(`/iag/project/overview`);
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async OptOut(payload: OptOutPayload) {
    try {
      return this.axios.post(`/iag/opt-out`, payload);
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async UploadSupportingDocument(payload: SupportingDocPayload) {
    try {
      const form = new FormData();
      form.append('file', payload.file);
      return this.axios.postForm(`/iag/submissions/${payload.submission}/supporting-document`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async GetSupportingDocuments(submission: string) {
    try {
      return this.axios.get(`/iag/submissions/${submission}/supporting-document`);
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async GetOptOuts() {
    try {
      return this.axios.get(`/iag/opt-out`);
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  ExportSubmissions() {
    return `${this.GetBaseUrl()}iag/reports/submissions/export`;
  }
}

export default new IagService(CoreConfig);
