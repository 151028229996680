import { faSpinner, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from '@mantine/core';
import { useEffect } from 'react';
import { useIagTokenSignin } from '../../register/hooks';

interface SigninHandlerProps {
  token: string;
}
export const SigninHandler = ({ token }: SigninHandlerProps) => {
  const { mutate, isError, error, isSuccess } = useIagTokenSignin();

  useEffect(() => {
    if (token) {
      mutate({
        token: token,
      });
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      window.location.href = '/app/home';
      return;
    }
  }, [isSuccess]);

  if (isError) {
    return (
      <Alert
        sx={{
          width: '20rem',
        }}
        variant="filled"
        color="red"
      >
        <p
          style={{
            fontSize: '1.25rem',
          }}
        >
          <FontAwesomeIcon icon={faWarning} />
          &nbsp; {error.error.message}
        </p>
      </Alert>
    );
  }
  return (
    <>
      <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="5x" />
      <h1>Loading</h1>
    </>
  );
};
