import { useQuery } from 'react-query';
import IagService from '../service/iag.service';

export interface Member {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  verified: string;
}

export const useTeamMembersQuery = () => {
  const { data, ...restQuery } = useQuery({
    queryKey: ['useTeamMembersQuery'],
    queryFn: async () => {
      return IagService.GetMembers();
    },
  });

  return {
    data: data?.data,
    ...restQuery,
  };
};
