import { Image, Header as MantineHeader, Title, createStyles } from '@mantine/core';
import { useGetProjectDetails } from '../../pages/login/hooks';

export function OptOutHeader() {
  const { data } = useGetProjectDetails();

  const headerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '1rem',
    backgroundColor: '#f1f1f1',
  };

  const alignCenter: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
  };

  const useStyles = createStyles(theme => ({
    hiddenMobile: {
      [theme.fn.smallerThan('lg')]: {
        display: 'none',
      },
    },

    hiddenDesktop: {
      [theme.fn.largerThan('lg')]: {
        display: 'none',
      },
    },

    navItems: {
      display: 'flex',
      alignItems: 'center',
    },

    projectHeading: {
      minWidth: '15rem',
      overflow: 'hidden',
      color: '#818181',
      fontSize: '1.7rem',
    },
  }));

  const { classes, cx } = useStyles();

  return (
    <>
      {data && (
        <>
          <MantineHeader height={100} style={headerStyle}>
            <div style={alignCenter}>
              <a href="/">
                <Image src={data.projectConfig.contents.logo} withPlaceholder fit="contain" width={200} />
              </a>
              <Title pl={'lg'} className={cx(classes.projectHeading, classes.hiddenMobile)}>
                IAG Shareholder Class Action
              </Title>
            </div>
          </MantineHeader>
        </>
      )}
    </>
  );
}
