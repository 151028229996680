import { Button, Container, Divider, Group, Paper, Table, Title } from '@mantine/core';
import { CreateTeam } from '../components/create-team';
import { Member, useTeamMembersQuery } from '../hooks/useTeamMembersQuery';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { EditTeam } from '../components/edit-team';

export const TeamManagementPage = () => {
  const { data } = useTeamMembersQuery();
  const [isOpen, { open: openEditModal, close: closeEditModal }] = useDisclosure(false);
  const [userToEdit, setUserToEdit] = useState<Member | null>(null);

  const selectUserToEdit = (user: Member) => {
    setUserToEdit(user);
    openEditModal();
  };

  const onCloseEditModal = () => {
    setUserToEdit(null);
    closeEditModal();
  };

  return (
    <>
      <Container size="xl" sx={{ marginTop: '2rem' }}>
        <Paper
          shadow="xl"
          sx={{
            padding: '2rem',
          }}
        >
          <Title order={2}>Team Management</Title>
          <Divider orientation="horizontal" size={'md'} variant="solid" sx={{ height: '1rem' }} color="gray" />
          <Group>
            <CreateTeam />
          </Group>
          <Table>
            <thead>
              <tr>
                <th>Email</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Verified Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {(data?.members ?? []).map((m: Member) => (
                <tr key={m.id}>
                  <td>{m.email}</td>
                  <td>{m.firstName}</td>
                  <td>{m.lastName}</td>
                  <td>{!!m.verified ? 'Verified' : 'Not Verified'}</td>
                  <td>
                    <div>
                      <Button type="button" color="orange" onClick={() => selectUserToEdit(m)}>
                        Edit
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Paper>
      </Container>
      {!!userToEdit && <EditTeam isOpen={isOpen} onClose={onCloseEditModal} userDetail={userToEdit} />}
    </>
  );
};
