import { Alert, Button, Group, LoadingOverlay, Paper, Stack, TextInput, Title } from '@mantine/core';
import { faWarning, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from '@mantine/hooks';
import { InferType } from 'yup';
import { formPaperHeadingStyle, formPaperStyle, formPaperStyleLarge } from '../../login/styles';
import { useIagRegistrationForm, useIagRegistrationMutation } from '../hooks';
import { iagRegistrationSchema } from '../schema';
import { SigninModal } from './signin-modal';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';

export default function IagRegisterForm() {
  const largeScreen = useMediaQuery('(min-width: 800px)');

  const { getInputProps, onSubmit } = useIagRegistrationForm();
  const { mutate, error, isError, isLoading, isSuccess } = useIagRegistrationMutation();

  const handleSubmit = (data: InferType<typeof iagRegistrationSchema>) => {
    mutate({
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      window.location.href = '/registration-success';
      return;
    }
  }, [isSuccess]);
  return (
    <>
      <Helmet>
        <title>IAG Shareholder Class Action</title>
      </Helmet>
      <Paper withBorder shadow={'md'} sx={largeScreen ? formPaperStyleLarge : formPaperStyle}>
        <Title align="center" order={2} styles={formPaperHeadingStyle}>
          IAG Shareholder Class Action
        </Title>
        <Title align="center" order={2} styles={formPaperHeadingStyle}>
          Register
        </Title>

        <form onSubmit={onSubmit(data => handleSubmit(data))}>
          <Stack spacing={10} mt="3rem">
            {isError && (
              <Alert variant="light" color="red" title="Warning" icon={<FontAwesomeIcon icon={faWarning} />}>
                {error.error.message}
              </Alert>
            )}

            <LoadingOverlay visible={isLoading} />

            <TextInput type="email" {...getInputProps('email')} label="Email" />
            <TextInput type="text" {...getInputProps('firstName')} label="First Name" />
            <TextInput type="text" {...getInputProps('lastName')} label="Last Name" />

            <Button type="submit" px={50} mt="xs" variant="outline">
              Register
            </Button>
          </Stack>
        </form>
        <Group grow={true} mt="xs">
          <SigninModal />
        </Group>
      </Paper>
    </>
  );
}
