import {
  Button,
  Container,
  Divider,
  Group,
  LoadingOverlay,
  Paper,
  Select,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PageNotFound from '../../component/common/page-not-found';
import { OptOutHeader } from '../../component/Header/OptOutHeader';
import { OptOutForm, useOptOutForm } from '../../pages/register/schema';
import { IsIagProject } from '../../utils/helper';
import { useOptOutMutation } from '../hooks/useOptOutMutation';
import { MemberType } from '../types';

export const OptOutPage = () => {
  const isIag = IsIagProject();
  const { getInputProps, onSubmit, reset, values } = useOptOutForm();
  const { mutate, isSuccess, isLoading } = useOptOutMutation();

  const isNotGroupMember = values.memberType !== 'GROUP_MEMBER' && values.memberType !== '';
  const onSubmitForm = (data: OptOutForm) => {
    mutate({
      fullName: data.fullName,
      email: data.email,
      memberType: data.memberType as MemberType,
      legalEntity: data.legalEntity ?? '',
      address: {
        address: data.address.address,
        suburban: data.address.suburban,
        state: data.address.state,
        postCode: data.address.postCode,
        country: data.address.country,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess, reset]);

  if (!isIag) {
    return <PageNotFound />;
  }

  return (
    <>
      <Helmet>
        <title>IAG Shareholder Class Action</title>
      </Helmet>
      <LoadingOverlay visible={isLoading} />
      <Container size="xl" fluid px={0}>
        <OptOutHeader />

        <Container
          size="lg"
          sx={{
            marginTop: '1rem',
            marginBottom: '10rem',
          }}
        >
          <Paper shadow="md" p="sm" withBorder>
            <form onSubmit={onSubmit(data => onSubmitForm(data))}>
              <Container sx={{ textAlign: 'center' }}>
                <Title order={3}>Schedule 2</Title>
                <Title order={3}>IAG SHAREHOLDER CLASS ACTION </Title>
              </Container>
              <Container sx={{ textAlign: 'center', marginTop: '0.5rem' }}>
                <Text>Damian Christopher Norris vs Insurance Australia Group Limited</Text>
                <Text> (S ECI 2022 02887) </Text>
              </Container>
              <Divider
                orientation="horizontal"
                size={'md'}
                variant="solid"
                sx={{ height: '1rem', marginTop: '1rem' }}
                color="gray"
              />
              <Container size="sm" sx={{ textAlign: 'center' }}>
                <Text size="md" color="dimmed" inline>
                  Only complete this form if you wish to <strong>OPT OUT</strong> of the IAG Shareholder Class Action.
                  If you Opt Out, you will no longer be a Group Member.
                </Text>
              </Container>
              <Container size="md" sx={{ marginTop: '2rem' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                  }}
                >
                  <TextInput type="text" label="Full Name" {...getInputProps('fullName')} />

                  <Select
                    label="Group Member Type"
                    sx={{}}
                    data={[
                      {
                        value: 'GROUP_MEMBER',
                        label: 'Group Member',
                      },
                      {
                        value: 'DIRECTOR',
                        label: 'Director',
                      },
                      {
                        value: 'EXECUTOR_ESTATE',
                        label: 'Executor for the Estate',
                      },
                      {
                        value: 'POWER_OF_ATTORNEY',
                        label: 'Power of Attorney',
                      },
                      {
                        value: 'SOLICITOR_ACTING',
                        label: 'Solicitor Acting',
                      },
                    ]}
                    {...getInputProps('memberType')}
                  />
                  {isNotGroupMember && <TextInput type="text" label="Legal Entity" {...getInputProps('legalEntity')} />}
                  <TextInput type="email" label="Email" {...getInputProps('email')} />
                  <div>
                    <Text
                      size="sm"
                      sx={{
                        fontStyle: 'italic',
                        fontWeight: 'bold',
                      }}
                    >
                      Address Details
                    </Text>
                    <TextInput type="text" label="Address" {...getInputProps('address.address')} />
                    <Group sx={{ marginTop: '1rem' }} grow>
                      <TextInput label="Suburb/Town" {...getInputProps('address.suburban')} />
                      <TextInput label="State" {...getInputProps('address.state')} />
                      <TextInput label="Post Code" {...getInputProps('address.postCode')} />
                      <TextInput label="Country" {...getInputProps('address.country')} />
                    </Group>
                  </div>
                </div>
              </Container>
              <Container sx={{ marginTop: '3rem' }}>
                <Title order={3} align="center">
                  Acknowledgements
                </Title>

                <Text>
                  By submitting this form, I acknowledge:
                  <ul style={{ marginTop: '0.25rem' }}>
                    <li>
                      That I give notice under section 33J(2) of the Supreme Court Act 1986 (Vic) that I am opting out
                      of this proceeding (if a group member) or on behalf of the group member whom I represent (if I am
                      a representative of that group member).
                    </li>
                    <li>
                      That the information I have provided in this form is true and correct. If those details change in
                      future, I will advise Quinn Emanuel in writing of those change(s) by using this portal or by
                      emailing <a href="mailto:optout@iagclassaction.com">optout@iagclassaction.com</a>.
                    </li>
                    <li>
                      Where I am not the Group Member, as the contact person:
                      <ul>
                        <li>
                          I complete and submit this form on behalf of the Group Member as its authorised agent and
                          confirm that I have all consents, authorities and approvals to do so; and
                        </li>
                        <li>I give the acknowledgments set out above for and on behalf of the Group Member.</li>
                      </ul>
                    </li>
                    <li>
                      That opt out information I have provided is being managed by Quinn Emanuel Urquhart & Sullivan
                      Lawyers ("Quinn Emanuel"), in compliance with orders of the Supreme Court of Victoria dated 5
                      December 2024.
                    </li>
                    <li>
                      Quinn Emanuel may be required to disclose personal information to external third parties as
                      required by law or otherwise for the purpose of conducting the IAG Shareholder Class Action.
                    </li>
                  </ul>
                </Text>
              </Container>
              <Container sx={{ marginTop: '3rem' }}>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                  <Button variant="outline" color="gray">
                    Cancel
                  </Button>
                  <Button type="submit">Submit</Button>
                </div>
              </Container>
            </form>
          </Paper>
          <br />
        </Container>
      </Container>
    </>
  );
};
