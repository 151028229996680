import { createContext, PropsWithChildren, useContext } from 'react';
import { useAccount } from '../hooks/useAccount';

interface IAuthContext {
  isLoading: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    verified: boolean;
  };
  refetch: () => void;
}

const IagAuthContext = createContext<IAuthContext | null>(null);

export const IagAuthProvider = ({ children }: PropsWithChildren) => {
  const { data, refetch, isLoading } = useAccount();
  const account = data?.account;
  return (
    <IagAuthContext.Provider
      value={{
        isLoading: isLoading,
        isAuthenticated: !!account?.email,
        isAdmin: account?.isAdmin ?? false,
        user: {
          firstName: account?.firstName ?? '',
          lastName: account?.firstName ?? '',
          email: account?.email ?? '',
          verified: !!account?.verified,
        },
        refetch,
      }}
    >
      {children}
    </IagAuthContext.Provider>
  );
};

export function useAuthProvider(): IAuthContext {
  const authContext = useContext(IagAuthContext);

  if (!authContext) {
    throw new Error('AxiosContext does not exist');
  }

  return authContext;
}
