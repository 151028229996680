import { useEffect } from 'react';
import { useIagTokenSignin } from '../register/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Container } from '@mantine/core';
import { SigninHandler } from './component/signin-handler';

export const TokenSigninPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginTop: '5rem',
      }}
    >
      {!!token && <SigninHandler token={token} key={token} />}
    </Container>
  );
};
