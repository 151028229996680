import { useMutation } from 'react-query';
import iagService from '../service/iag.service';
import { showNotification } from '@mantine/notifications';

export const useLogout = () => {
  return useMutation(
    async () => {
      return await iagService.Logout();
    },
    {
      onSuccess: () => {
        window.location.href = '/register';
      },
    },
  );
};
