import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import iagService from '../service/iag.service';

interface SupportingDocument {
  id: string;
  fileUrl: string;
  fileName: string;
}

export const useSupportingDocuments = (submission: string) => {
  const { data, ...restQuery } = useQuery<AxiosResponse<{ attachments: SupportingDocument[] }>>({
    queryKey: ['useSupportingDocuments', submission],
    queryFn: async () => {
      return iagService.GetSupportingDocuments(submission);
    },
  });

  return {
    ...restQuery,
    data: data?.data,
  };
};
