import { showNotification } from '@mantine/notifications';
import { useMutation } from 'react-query';
import { IErrorObject } from '../../services/types';
import { useAuthProvider } from '../providers/iag-auth.provider';
import IagService from '../service/iag.service';
import { SubmissionSchemaV2 } from '../types';

interface Payload extends SubmissionSchemaV2 {
  actionType: string;
}
interface SubmissionPayload {
  id: string;
  submission: Payload;
}

export const useSubmissionMutation = () => {
  const { isAdmin } = useAuthProvider();
  return useMutation<any, IErrorObject, SubmissionPayload, any>(
    async (payload: SubmissionPayload) => {
      return await IagService.UpdateSubmission(payload.id, payload.submission);
    },
    {
      onSuccess: (_, variables: SubmissionPayload) => {
        if (variables.submission.actionType === 'DRAFTED') {
          window.location.href = '/success-save-draft';
          return;
        }
        showNotification({
          id: 'update-success',
          title: 'Success',
          message: 'Submission success',
          autoClose: 3000,
          color: 'green',
        });
        setTimeout(() => {
          let href = '/app/home';
          if (isAdmin) {
            href = '/app/admin/submissions';
          }
          window.location.href = href;
        }, 1000);
      },
      onError(error) {
        showNotification({
          id: 'update-errir',
          title: 'Error',
          message: error.error.message,
          autoClose: 3000,
          color: 'red',
        });
      },
    },
  );
};
