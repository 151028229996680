import { useQuery } from 'react-query';
import IagService from '../service/iag.service';
import { AxiosResponse } from 'axios';
import { Address } from '../types';

interface OptOut {
  id: string;
  legalEntity: string;
  email: string;
  contactPerson: string;
  address: Address;
  memberType: string;
}
export const useOptOutsQuery = () => {
  const { data, ...restQuery } = useQuery<AxiosResponse<{ optOuts: OptOut[] }>>({
    queryKey: ['useOptOutsQuery'],
    queryFn: async () => {
      return IagService.GetOptOuts();
    },
  });

  return {
    data: data?.data,
    ...restQuery,
  };
};
