import { useForm, yupResolver } from '@mantine/form';
import { useMutation } from 'react-query';
import { IErrorObject } from '../../services/types';
import RegisterModel from './model/register.model';
import { iagLoginSchema, iagRegistrationSchema, registrationSchema, sendEmailVerificationSchema } from './schema';
import { EmailSignin, IagRegister, IRegisterFormProps, TokenSignin } from './types';
import { showNotification } from '@mantine/notifications';

export function useRegistrationForm() {
  return useForm({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validate: yupResolver(registrationSchema),
  });
}

export function useIagRegistrationForm() {
  return useForm({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
    validate: yupResolver(iagRegistrationSchema),
  });
}

export function useIagLoginForm() {
  return useForm({
    initialValues: {
      email: '',
    },
    validate: yupResolver(iagLoginSchema),
  });
}

export const useProcessRegistration = () => {
  return useMutation<void, IErrorObject, IRegisterFormProps, void>(async ({ email, password }: IRegisterFormProps) => {
    return await RegisterModel.Register({ email, password });
  }, {});
};

export const useProcessConfirmation = () => {
  return useMutation<any, IErrorObject, { code: string }, any>(async ({ code }: { code: string }) => {
    return await RegisterModel.Verify({ code });
  }, {});
};

export function useSendVerificationForm() {
  return useForm({
    initialValues: {
      email: '',
    },
    validate: yupResolver(sendEmailVerificationSchema),
  });
}

export const useProcessResendVerification = () => {
  return useMutation<any, IErrorObject, { email: string }, any>(async ({ email }: { email: string }) => {
    return await RegisterModel.Resend({ email });
  }, {});
};

export const useIagRegistrationMutation = () => {
  return useMutation<any, IErrorObject, IagRegister, any>(async (payload: IagRegister) => {
    return await RegisterModel.IagRegister(payload);
  }, {});
};

export const useIagTeamRegistrationMutation = () => {
  return useMutation<any, IErrorObject, IagRegister, any>(
    async (payload: IagRegister) => {
      return await RegisterModel.IagRegisterTeamMember(payload);
    },
    {
      onError(error) {
        showNotification({
          id: 'register-errir',
          title: 'Error',
          message: error.error.message,
          autoClose: 3000,
          color: 'red',
        });
      },
    },
  );
};

export const useIagLogin = () => {
  return useMutation<any, IErrorObject, EmailSignin, any>(async (payload: EmailSignin) => {
    return await RegisterModel.IagEmailSignin(payload);
  }, {});
};

export const useIagTokenSignin = () => {
  return useMutation<any, IErrorObject, TokenSignin, any>(
    async (payload: TokenSignin) => {
      return await RegisterModel.IagVerifySigninToken(payload);
    },
    {
      retry: false,
    },
  );
};

export const useVerifyAccount = () => {
  return useMutation<any, IErrorObject, TokenSignin, any>(
    async (payload: TokenSignin) => {
      return await RegisterModel.IagVerifyAccount(payload);
    },
    {
      retry: false,
    },
  );
};
