import { faCloudUpload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, List, LoadingOverlay, Paper, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { useEffect } from 'react';
import { File } from 'tabler-icons-react';
import { useUploadSupportingDocs } from '../hooks/useUploadSupportingDocs';
import { useSupportingDocuments } from '../hooks/useSupportingDocuments';

interface SupportingDocumentsProps {
  submissionId: string;
}
export const SupportingDocuments = ({ submissionId }: SupportingDocumentsProps) => {
  const MAX_FILE_SIZE = 15 * 1024 * 1024;
  const { data: attachedDocumentsData, refetch } = useSupportingDocuments(submissionId);
  const documents = attachedDocumentsData?.attachments ?? [];
  const { mutate, isSuccess, isLoading } = useUploadSupportingDocs();
  const dropzoneChildren = () => {
    return (
      <Stack sx={{ alignItems: 'center', cursor: 'pointer' }}>
        {isLoading ? (
          <FontAwesomeIcon icon={faSpinner} size="2x" spin />
        ) : (
          <FontAwesomeIcon icon={faCloudUpload} size="2x" />
        )}

        <div>
          <Text size="sm" color="dimmed" inline>
            Select File to Upload
          </Text>
        </div>
      </Stack>
    );
  };

  const onDrop = async (files: File[]) => {
    const file = files[0];

    if (file) {
      mutate({
        file: file,
        submission: submissionId,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess, refetch]);

  return (
    <>
      <Paper
        shadow="xl"
        sx={{
          padding: '2rem',
          marginTop: '1rem',
        }}
      >
        <Title order={2}>Part D - Supporting Documents</Title>
        <Divider orientation="horizontal" size={'md'} variant="solid" sx={{ height: '1rem' }} color="gray" />
        <Text align="justify">
          Please upload any supporting documents evidencing your transactions in IAG Shares using the below upload
          links. Supporting documents include trade confirmations, CHESS statements and contract notes.
        </Text>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            marginBottom: '1rem',
          }}
        >
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Dropzone
              sx={{
                width: '50%',
              }}
              onDrop={onDrop}
              disabled={false}
              maxSize={MAX_FILE_SIZE}
              accept={[MIME_TYPES.jpeg, MIME_TYPES.png, MIME_TYPES.pdf, MIME_TYPES.docx, MIME_TYPES.doc]}
              styles={{
                root: {
                  margin: '0.5rem',
                  width: '100%',
                },
              }}
            >
              {status => dropzoneChildren()}
            </Dropzone>
          </Box>
          <List
            listStyleType="none"
            spacing="xs"
            withPadding
            icon={
              <ThemeIcon color="teal" size={24} radius="xl">
                <File size={16} />
              </ThemeIcon>
            }
          >
            {documents.map(i => (
              <List.Item key={i.id}>
                <Text variant="link" component="a" href={i.fileUrl} target="_blank">
                  {i.fileName}
                </Text>
              </List.Item>
            ))}
          </List>
        </div>
      </Paper>
    </>
  );
};
