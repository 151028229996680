import { Button, Group, Modal, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { iagRegistrationSchema } from '../../pages/register/schema';
import { InferType } from 'yup';
import { useIagTeamRegistrationMutation } from '../../pages/register/hooks';
import { useEffect } from 'react';
import { useTeamMembersQuery } from '../hooks/useTeamMembersQuery';

export const CreateTeam = () => {
  const [isOpen, { open, close }] = useDisclosure(false);
  const { refetch } = useTeamMembersQuery();
  const { mutate, isSuccess, isLoading } = useIagTeamRegistrationMutation();
  const { getInputProps, onSubmit, reset } = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validate: yupResolver(iagRegistrationSchema),
  });

  const onSubmitTeam = (data: InferType<typeof iagRegistrationSchema>) => {
    mutate({
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      refetch();
      close();
    }
  }, [isSuccess]);
  return (
    <>
      <Button type="button" onClick={() => open()} my={3}>
        Add Team
      </Button>
      <Modal closeOnClickOutside={false} title="Team Member" opened={isOpen} closeOnEscape onClose={close}>
        <form onSubmit={onSubmit(value => onSubmitTeam(value))}>
          <TextInput type="email" {...getInputProps('email')} label="Email" />
          <TextInput type="text" {...getInputProps('firstName')} label="First Name" />
          <TextInput type="text" {...getInputProps('lastName')} label="Last Name" />
          <Group position="center" mt={10}>
            <Button variant="outline" color="gray" onClick={close}>
              Cancel
            </Button>
            <Button variant="outline" type="submit" disabled={isLoading}>
              {isLoading ? 'Saving' : 'Save'}
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};
