import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import iagService from '../service/iag.service';

export interface ITrade {
  id: string;
  transactionDate: string;
  transactionType: string;
  quantity: string;
  sharePrice: string;
  amountPaid: string;
  subType: string;
}
export const useTradeTransactions = (submissionId: string) => {
  const { data, ...restQuery } = useQuery<AxiosResponse<{ transactions: ITrade[] }>>({
    queryKey: ['useTradeTransactions'],
    queryFn: async () => {
      return iagService.GetTradeTransactions(submissionId);
    },
  });

  return {
    ...restQuery,
    data: data?.data,
  };
};
