import { useQuery } from 'react-query';
import IagService from '../service/iag.service';

export const useAccount = () => {
  const { data, ...restQuery } = useQuery({
    queryKey: ['account-me'],
    queryFn: async () => {
      return IagService.GetProfile();
    },
  });

  return {
    data: data?.data,
    ...restQuery,
  };
};
