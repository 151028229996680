import { Divider, Paper, Text, Title } from '@mantine/core';

export const Acknowledgement = () => {
  return (
    <Paper
      shadow="xl"
      sx={{
        padding: '2rem',
        marginTop: '1rem',
      }}
    >
      <Title order={2}>Part E - Acknowledgements</Title>
      <Divider orientation="horizontal" size={'md'} variant="solid" sx={{ height: '1rem' }} color="gray" />
      <Text size="md" color="dark" align="justify">
        <p>To finalise points collection of points below (some double ups):</p>
        <p>When you submit this form you acknowledge:</p>
        <ul style={{ marginTop: '0' }}>
          <li>
            Registration Information is being managed by Quinn Emanuel Urquhart & Sullivan Lawyers (
            <strong>“Quinn Emanuel”</strong>), in compliance with orders of the Supreme Court of Victoria dated 5
            December 2024;
          </li>
          <li>
            Quinn Emanuel will manage the information provided in this submission in accordance with its privacy policy
            available <a href="https://www.quinnemanuel.com/privacy-policy/">here</a>.
          </li>
          <li>
            Quinn Emanuel may be required to disclose personal information to external third parties as required by law
            or otherwise for the purpose of conducting the IAG Shareholder Class Action;
          </li>
          <li>
            The Registration Information I have provided in this form is correct and current in every detail and, should
            these details change in future, I will advise Quinn Emanuel in writing of the change(s) using this portal or
            by emailing <a href="mailto:register@iagclassaction.com">register@iagclassaction.com</a>;
          </li>
          <li>
            Where I am not the Group Member, as the contact person:
            <ul>
              <li>
                I complete and submit this form on behalf of the Group Member as its authorised agent and confirm that I
                have all consents, authorities and approvals to do so;
              </li>
              <li> I give the acknowledgments set out above for and on behalf of the Group Member.</li>
            </ul>
          </li>
        </ul>
      </Text>
    </Paper>
  );
};
