import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import iagService from '../service/iag.service';

interface AdminSubmissionResponse {
  email: string;
  firstName: string;
  lastName: string;
  id: string;
  legalEntity: string;
  rgm: string | null;
  status: string;
}

interface Pagination {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  page: number;
  records: number;
}

interface AdminSubmissions {
  submissions: AdminSubmissionResponse[];
  pagination: Pagination;
}
interface Filter {
  page: number;
  search?: string;
}
export const useAdminSubmissionsQuery = (filter: Filter) => {
  const query = new URLSearchParams();
  query.set('page', filter.page.toString());
  if (filter?.search && filter.search.length >= 3) {
    query.set('search', filter.search);
  }
  const { data, ...restQuery } = useQuery<AxiosResponse<AdminSubmissions>>({
    queryKey: ['useAdminSubmissionsQuery', filter.page, filter.search],
    queryFn: async () => {
      return iagService.AdminGetSubmissions(query.toString());
    },
  });

  return {
    ...restQuery,
    data: data?.data,
  };
};
