import { useMutation } from 'react-query';
import { IErrorObject } from '../../services/types';
import iagService from '../service/iag.service';

export interface SupportingDocPayload {
  file: File;
  submission: string;
}

export const useUploadSupportingDocs = () => {
  return useMutation<any, IErrorObject, SupportingDocPayload, any>(
    async (payload: SupportingDocPayload) => {
      return await iagService.UploadSupportingDocument(payload);
    },
    {
      onError(error) {},
    },
  );
};
