import { PathRouteProps } from 'react-router-dom';
import { IAG_NAV, NAV } from './config/navigation';
/* component entry points */
import Notification from './component/common/notification';
import { Form } from './pages/form-field';
import CustomerLogin from './pages/login/auth';
import Register from './pages/register';
import RegisterConfirmation from './pages/register/component/confirm';
import UserSubmissionList from './pages/submission/me/';
import UserSubmissionView from './pages/submission/me/view';
import { ProjectPage } from './pages/project/pages';
import ResetPassword from './pages/reset-password';
import SetPassword from './pages/set-password';

/** ADMIN page components **/
import SubmissionsList from './admin/submissions/list';
import SubmissionsView from './admin/submissions/view';
import ProjectSettings from './admin/project-settings/';
import UserList from './admin/user/';
import { TokenSigninPage } from './pages/token-signin/token-signin.page';
import { AccountVerificationPage } from './pages/account-verification/account-verification.page';
import { IagHome } from './pages/iag-home';
import { IagSubmissionPage } from './iag/pages/submission.page';
import { TeamManagementPage } from './iag/pages/team-management.page';
import { AdminSubmissionsPage } from './iag/pages/admin-submissions.page';
import { OptOutPage } from './iag/pages/opt-out.page';
import { Maintenance } from './component/maintenance';
import { AdminOptoutsPage } from './iag/pages/admin-optouts.page';
import { SuccessConfirmationPage } from './iag/pages/success-confirmation.page';
import { RegistrationSuccessPage } from './iag/pages/registration-success.page';
import { DraftSavedPage } from './iag/pages/draft-saved.page';

const publicRoutes: PathRouteProps[] = [
  { path: NAV.LOGIN, element: <CustomerLogin /> },
  { path: NAV.HOME, element: <ProjectPage forcePath={'index'} /> },
  { path: NAV.REGISTER, element: <Register /> },
  { path: NAV.REGISTER_NOTIF, element: <Notification registration /> },
  { path: NAV.REGISTER_SUCCESS, element: <Notification verified /> },
  { path: NAV.REGISTER_CONFIRM, element: <RegisterConfirmation /> },
  { path: NAV.RESET_PASSWORD, element: <ResetPassword /> },
  { path: NAV.RESET_PASSWORD_SENT, element: <Notification resetPassword /> },
  { path: NAV.RESET_PASSWORD_SUCCESS, element: <Notification changed /> },
  { path: NAV.SET_PASSWORD, element: <SetPassword /> },
  { path: NAV.PROJECT_PAGE, element: <ProjectPage /> },
  {
    path: IAG_NAV.TOKEN_SIGNIN,
    element: <TokenSigninPage />,
  },
  {
    path: IAG_NAV.ACCOUNT_VERIFICATION,
    element: <AccountVerificationPage />,
  },
  {
    path: IAG_NAV.OPT_OUT,
    element: <OptOutPage />,
  },
  {
    path: 'ongoing-improvements',
    element: <Maintenance />,
  },
  {
    path: 'login-confirmation-success',
    element: <SuccessConfirmationPage />,
  },
  {
    path: 'registration-success',
    element: <RegistrationSuccessPage />,
  },
  {
    path: 'success-save-draft',
    element: <DraftSavedPage />,
  },
];

const protectedRoutes = [
  { path: NAV.FORM, element: <Form /> },
  { path: NAV.USER_SUBMISSIONS_LIST, element: <UserSubmissionList /> },
  { path: NAV.USER_SUBMISSIONS_VIEW, element: <UserSubmissionView /> },
];

const adminProtectedRoutes = [
  { path: NAV.ADMIN, element: <SubmissionsList /> },
  { path: NAV.ADMIN_SUBMISSION_VIEW, element: <SubmissionsView /> },
  { path: NAV.ADMIN_PROJECT_SETTINGS, element: <ProjectSettings /> },
  { path: NAV.ADMIN_USER_LIST, element: <UserList /> },
];

const iagRoutes = [
  {
    path: '/app/home',
    element: <IagHome />,
  },
  { path: `/app/admin/submissions-old/:id`, element: <SubmissionsView /> },
  {
    path: '/app/admin/submissions-old',
    element: <SubmissionsList />,
  },
  {
    path: '/app/admin/submissions',
    element: <AdminSubmissionsPage />,
  },
  {
    path: '/app/admin/submissions-optout',
    element: <AdminOptoutsPage />,
  },
  {
    path: '/app/admin/team-management',
    element: <TeamManagementPage />,
  },
  {
    path: '/app/submission/:submissionId/view',
    element: <IagSubmissionPage />,
  },
];
export { publicRoutes, protectedRoutes, adminProtectedRoutes, iagRoutes };
