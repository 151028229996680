import { showNotification } from '@mantine/notifications';
import { useMutation } from 'react-query';
import { IErrorObject } from '../../services/types';
import IagService from '../service/iag.service';

interface TradePayload {
  submissionId: string;
  tradeId: string;
}

export const useDeleteTrade = () => {
  return useMutation<any, IErrorObject, TradePayload, any>(
    async (payload: TradePayload) => {
      return await IagService.DeleteTradeTransaction(payload.tradeId, payload.submissionId);
    },
    {
      onError(error) {
        showNotification({
          id: 'update-errir',
          title: 'Error',
          message: error.error.message,
          autoClose: 3000,
          color: 'red',
        });
      },
    },
  );
};
