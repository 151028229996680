import { showNotification } from '@mantine/notifications';
import { useMutation } from 'react-query';
import { IErrorObject } from '../../services/types';
import IagService from '../service/iag.service';
import { OptOutPayload } from '../types';

export const useOptOutMutation = () => {
  return useMutation<any, IErrorObject, OptOutPayload, any>(
    async (payload: OptOutPayload) => {
      return await IagService.OptOut(payload);
    },
    {
      onSuccess: () => {
        showNotification({
          id: 'update-success',
          title: 'Success',
          message: 'An acknowledgement has been sent to your email',
          autoClose: 3000,
          color: 'green',
        });
        setTimeout(() => {
          window.location.href = '/';
        }, 3000);
      },
      onError(error) {
        showNotification({
          id: 'update-errir',
          title: 'Error',
          message: error.error.message,
          autoClose: 3000,
          color: 'red',
        });
      },
    },
  );
};
