import { Badge, Button, Container, Group, LoadingOverlay, Paper, Space, Text, Title } from '@mantine/core';
import { useEffect } from 'react';
import { ChevronRight, Plus } from 'tabler-icons-react';
import Alert from '../../../component/common/alert';
import { useInitSubmission } from '../../../iag/hooks/useInitSubmission';
import { useSubmissionsQuery } from '../../../iag/hooks/useSubmissionsQuery';
import { SubmissionStatus } from '../../../iag/types';

export const CustomerHome = () => {
  const { data, isLoading: isLoadingSubmissions } = useSubmissionsQuery();
  const { mutate, isLoading, isSuccess } = useInitSubmission();
  const initSubmission = () => {
    mutate();
  };

  useEffect(() => {
    if (Array.isArray(data?.submissions) && data?.submissions.length === 1) {
      const firstItem = data?.submissions[0];
      if (firstItem.status === SubmissionStatus.DRAFTED) {
        window.location.href = `/app/submission/${firstItem.id}/view`;
      }
    }
  }, [data]);

  if (isLoadingSubmissions || isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <Container size="sm" sx={{ marginTop: '2rem' }}>
      <Paper
        withBorder
        shadow="xl"
        sx={{
          padding: '2rem',
        }}
      >
        <Group
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Title order={3} align="justify">
            Submissions
          </Title>
          <Button
            type="button"
            variant="filled"
            leftIcon={<Plus size={14} />}
            onClick={initSubmission}
            disabled={isLoading || isSuccess}
          >
            Submission
          </Button>
        </Group>
        <Space h={20} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.25rem',
          }}
        >
          {(data?.submissions ?? []).map(s => (
            <Paper
              tabIndex={1}
              key={s.id}
              onClick={() => {
                window.location.href = `/app/submission/${s.id}/view`;
              }}
              style={{
                cursor: 'pointer',
              }}
              px={'sm'}
              py={'md'}
              shadow="md"
              withBorder
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                ':hover': {
                  opacity: '0.5',
                },
              }}
            >
              <div>
                <Text sx={{ display: 'flex', gap: '0.15rem' }}>{!!s.beneficiary ? s.beneficiary : s.legalEntity}</Text>
                <Badge size="xs" variant="outline" color={s.status === 'SUBMITTED' ? 'blue' : 'gray'}>
                  {s.status}
                </Badge>
              </div>

              <ChevronRight
                size={32}
                style={{
                  fontWeight: 'bold',
                }}
              />
            </Paper>
          ))}
        </div>

        {(data?.submissions ?? []).length < 0 && (
          <>
            <Space h={20} />
            <Alert
              title="You do not have any submissions."
              color="blue"
              message="Click “+ Submission” to commence. Once you've finished, you can use it again to register for more than one entity."
            />
          </>
        )}
      </Paper>
    </Container>
  );
};
