import { Container, LoadingOverlay } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { SubmissionForm } from '../components/forms/submission-form';
import { useSubmission } from '../hooks/useSubmissionQuery';
import { useUploadSupportingDocs } from '../hooks/useUploadSupportingDocs';

export const IagSubmissionPage = () => {
  const { submissionId } = useParams();

  const { data, isLoading } = useSubmission(submissionId);
  const { isLoading: isUploading } = useUploadSupportingDocs();

  return (
    <>
      <LoadingOverlay visible={isLoading || isUploading} />
      <Container size="lg" sx={{ marginTop: '2rem' }}>
        {data?.id && (
          <SubmissionForm
            submissionId={data?.id}
            initialData={data.submission.data}
            submissionStatus={data.submission.status}
          />
        )}
      </Container>
    </>
  );
};
