import { Button, Container, Divider, LoadingOverlay, Paper, Table, Title } from '@mantine/core';
import { useOptOutsQuery } from '../hooks/useOptOutQuery';

export const AdminOptoutsPage = () => {
  const { data, isLoading } = useOptOutsQuery();
  console.log(data?.optOuts);
  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <Container size="xl" sx={{ marginTop: '2rem' }}>
        <Paper
          shadow="xl"
          sx={{
            padding: '2rem',
          }}
        >
          <Title order={2}>Submissions - Opt Out</Title>
          <Divider orientation="horizontal" size={'md'} variant="solid" sx={{ height: '1rem' }} color="gray" />

          <Container
            sx={{
              overflowX: 'scroll',
            }}
            fluid
            px="0"
          >
            <Table
              border={1}
              highlightOnHover
              verticalSpacing="xs"
              style={{ whiteSpace: 'nowrap', border: '1px solid #dfe1e2' }}
              className="submission-table"
            >
              <thead>
                <tr>
                  <th className="submission-table-header-name">Legal Entity</th>
                  <th className="submission-table-header-name">Member Type</th>
                  <th className="submission-table-header-name">Contact Email</th>
                  <th className="submission-table-header-name">Contact Person</th>
                  <th className="submission-table-header-name">Address</th>
                </tr>
              </thead>
              <tbody>
                {(data?.optOuts ?? []).map(s => (
                  <tr key={s.id}>
                    <td>{s.legalEntity}</td>
                    <td>{s.memberType}</td>
                    <td>{s.email}</td>
                    <td>{s.contactPerson}</td>
                    <td>
                      <address>
                        {s.address.address}
                        <br />
                        {`${s.address.suburban}, ${s.address.state}, ${s.address.postCode}`}
                        <br />
                        {s.address.country}
                      </address>
                      <div></div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </Paper>
      </Container>
    </>
  );
};
