import { Alert, Button, Group, Modal, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useIagLogin, useIagLoginForm } from '../hooks';
import { InferType } from 'yup';
import { iagLoginSchema } from '../schema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';

export const SigninModal = () => {
  const [isOpen, { toggle: toggleDrawer, close }] = useDisclosure(false);
  const { getInputProps, onSubmit } = useIagLoginForm();
  const { mutate, isSuccess: isSuccessEmailLogin, isError, error, isLoading } = useIagLogin();

  const login = (data: InferType<typeof iagLoginSchema>) => {
    mutate({
      email: data.email,
    });
  };

  useEffect(() => {
    if (isSuccessEmailLogin) {
      window.location.href = '/login-confirmation-success';
      return;
    }
  }, [isSuccessEmailLogin]);
  return (
    <>
      <Button type="button" onClick={() => toggleDrawer()} fullWidth>
        Email already verified? Login here.
      </Button>
      <Modal closeOnClickOutside={false} title="Log In" opened={isOpen} closeOnEscape onClose={close}>
        {isError && (
          <Alert variant="light" color="red" title="Warning" icon={<FontAwesomeIcon icon={faWarning} />}>
            {error.error.message}
          </Alert>
        )}
        <form onSubmit={onSubmit(value => login(value))}>
          <TextInput type="email" {...getInputProps('email')} label="Email" />

          <Group position="center" mt={10}>
            <Button variant="outline" color="gray" onClick={close}>
              Cancel
            </Button>
            <Button variant="outline" type="submit" loading={isLoading}>
              Log In
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};
