import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Container } from '@mantine/core';
import { Helmet } from 'react-helmet';
import { OptOutHeader } from '../../component/Header/OptOutHeader';

export const DraftSavedPage = () => {
  return (
    <>
      <Helmet>
        <title>IAG Shareholder Class Action</title>
      </Helmet>
      <OptOutHeader />
      <Container size="xl" fluid px={0}>
        <Container
          size="lg"
          sx={{
            marginTop: '1rem',
            marginBottom: '10rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Alert
            sx={{
              width: '50rem',
            }}
            variant="filled"
            color="orange"
          >
            <p
              style={{
                fontSize: '1rem',
                textAlign: 'center',
              }}
            >
              <FontAwesomeIcon size="4x" icon={faWarning} />
              <br />
              <span style={{ fontSize: '1.15rem' }}>
                Please note - your submission is currently saved as a draft and is not likely to be considered a valid
                registration submission until you provide all required information.
              </span>

              <br />
              <br />
              <Button component="a" href="/app/home" variant="white">
                Return
              </Button>
            </p>
          </Alert>
        </Container>
      </Container>
    </>
  );
};
