import { Alert, Button, Group, Modal, Select, TextInput } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { InferType } from 'yup';
import { tradeDetailSchema, useTradeDetailForm } from '../../pages/register/schema';
import { useCreateTradeMutation } from '../hooks/useCreateTradeMutation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';

interface TradeDetailsModalProps {
  submissionId: string;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}
export const TradeDetailsModal = ({ isOpen, onClose, submissionId, refetch }: TradeDetailsModalProps) => {
  const { getInputProps, onSubmit, reset } = useTradeDetailForm();
  const { mutate, isSuccess, isLoading, error, isError } = useCreateTradeMutation();

  const handleSubmit = (data: InferType<typeof tradeDetailSchema>) => {
    const payload = {
      id: submissionId,
      trade: {
        transactionDate: DateTime.fromJSDate(new Date(data.tradeDate)).toFormat('dd/MM/yyyy'),
        transactionType: data.transactionType,
        quantity: data.quantity,
        sharePrice: data.sharePrice,
        totalAmount: data.amountPaid,
        subType: data.subType ?? '',
      },
    };
    mutate(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      refetch();
      onClose();
    }
  }, [isSuccess]);

  return (
    <Modal closeOnClickOutside={false} title="Trade Details" opened={isOpen} closeOnEscape={false} onClose={onClose}>
      {isLoading && (
        <Alert sx={{}} variant="filled" color="orange">
          Saving transaction. Please wait.
        </Alert>
      )}
      {isError && (
        <Alert variant="light" color="red" title="Warning" icon={<FontAwesomeIcon icon={faWarning} />}>
          {error?.response?.data.meta[0] ?? 'Error'}
        </Alert>
      )}

      <form
        style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
        onSubmit={onSubmit(data => handleSubmit(data))}
      >
        <Select
          label="Transaction Type"
          //  sx={{ width: '49%' }}
          data={[
            {
              value: 'BUY',
              label: 'Buy',
            },
            {
              value: 'SELL',
              label: 'Sell',
            },
            {
              value: 'TRANSFER_IN',
              label: 'Transfer In',
            },
            {
              value: 'TRANSFER_OUT',
              label: 'Transfer Out',
            },
          ]}
          {...getInputProps('transactionType')}
        />
        <DatePicker
          label="Transaction Date"
          inputFormat="DD/MM/YYYY"
          placeholder="DD/MM/YYYY" //change this in the future to reflect settings from columnConfig
          labelFormat="MMM-YYYY" //change this in the future to reflect settings from columnConfig
          {...getInputProps('tradeDate')}
        />
        <TextInput
          type="text"
          label={
            <>
              Quantity: (
              <span>
                <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>Whole number only, no commas
              </span>
              )
            </>
          }
          {...getInputProps('quantity')}
        />
        <TextInput
          type="text"
          label={
            <>
              Price Per Share: (
              <span>
                <span style={{ color: 'red' }}>*</span>No commas or dollar signs
              </span>
              )
            </>
          }
          {...getInputProps('sharePrice')}
        />
        <TextInput
          type="text"
          label={
            <>
              Total Amount: (
              <span>
                <span style={{ color: 'red' }}>*</span>No commas or dollar signs
              </span>
              )
            </>
          }
          {...getInputProps('amountPaid')}
        />
        <Select
          label="Sub Type"
          data={[
            {
              value: 'OFF_MARKET_TRANSFER',
              label: 'Off Market Transfer',
            },
            {
              value: 'ON_MARKET',
              label: 'On-Market',
            },
            {
              value: 'DRP',
              label: 'DRP',
            },
            {
              value: 'CAPITAL_RAISING',
              label: 'Capital Raising',
            },
            {
              value: 'SHORT_SELLING',
              label: 'Short Selling',
            },
            {
              value: 'EQUITY_SWAP_CONFIRMATION',
              label: 'Equity Swap Confirmation',
            },
          ]}
          {...getInputProps('subType')}
        />
        <Group position="center" mt={10}>
          <Button variant="outline" color="gray" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button variant="outline" type="submit" loading={isLoading}>
            Save
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
