import { showNotification } from '@mantine/notifications';
import { useMutation } from 'react-query';
import { IErrorObject } from '../../services/types';
import IagService from '../service/iag.service';
import { TradeData } from '../types';
import { AxiosError } from 'axios';

interface TradePayload {
  id: string;
  trade: TradeData;
}

export const useCreateTradeMutation = () => {
  return useMutation<any, AxiosError<IErrorObject>, TradePayload, any>(
    async (payload: TradePayload) => {
      return await IagService.CreateTradeTransaction(payload.id, payload.trade);
    },
    {
      onError(error) {
        console.log(error?.response?.data.error.message);
        showNotification({
          id: 'update-errir',
          title: 'Error',
          message: error?.response?.data.meta[0] ?? 'Error',
          autoClose: 3000,
          color: 'red',
        });
      },
    },
  );
};
