import { Button, Group, Modal } from '@mantine/core';
import { useDeleteTrade } from '../hooks/useDeleteTrade';
import { useEffect } from 'react';

interface DeleteTradeDialogProps {
  tradeId: string;
  submissionId: string;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

export const DeleteTradeDialog = ({ submissionId, tradeId, isOpen, onClose, refetch }: DeleteTradeDialogProps) => {
  const { mutate, isLoading, isSuccess } = useDeleteTrade();
  const onConfirmDelete = () => {
    mutate({
      submissionId: submissionId,
      tradeId: tradeId,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onClose();
    }
  }, [isSuccess]);
  return (
    <Modal closeOnClickOutside={false} title="Confirm Delete" opened={isOpen} closeOnEscape onClose={onClose}>
      <Group align="center" position="center">
        <Button onClick={onClose} variant="outline" color="gray">
          Cancel
        </Button>
        <Button onClick={onConfirmDelete} disabled={isLoading} color="red">
          Confirm
        </Button>
      </Group>
    </Modal>
  );
};
