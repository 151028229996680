import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import iagService from '../service/iag.service';
import { SubmissionStatus } from '../types';

export interface ISubmissionItem {
  id: string;
  legalEntity: string;
  beneficiary: string;
  email: string;
  status: SubmissionStatus;
}
export const useSubmissionsQuery = () => {
  const { data, ...restQuery } = useQuery<AxiosResponse<{ submissions: ISubmissionItem[] }>>({
    queryKey: ['useSubmissionsQuery'],
    queryFn: async () => {
      return iagService.GetSubmission();
    },
  });

  return {
    ...restQuery,
    data: data?.data,
  };
};
