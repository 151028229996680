import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Anchor,
  Avatar,
  Burger,
  Button,
  Divider,
  Drawer,
  Group,
  Image,
  Header as MantineHeader,
  Menu,
  Paper,
  ScrollArea,
  Text,
  Title,
  createStyles,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLogout } from '../../iag/hooks/useLogout';
import { useAuthProvider } from '../../iag/providers/iag-auth.provider';
import { useGetProjectDetails } from '../../pages/login/hooks';

export function IagHeader() {
  const [open, setOpen] = useState(false);
  const { data } = useGetProjectDetails();
  const { mutate } = useLogout();

  const handleLogout = async () => {
    mutate();
  };
  const adminMenu = [
    {
      label: 'Home',
      route: '/app/home',
    },
    {
      label: 'Submissions - Pre R & OO',
      route: '/app/admin/submissions-old',
    },
    {
      label: 'Submissions - R & OO',
      route: '/app/admin/submissions',
    },
    {
      label: 'Submissions - Opt Out',
      route: '/app/admin/submissions-optout',
    },
    {
      label: 'Team Management',
      route: '/app/admin/team-management',
    },
  ];

  const headerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    backgroundColor: '#f1f1f1',
  };

  const alignCenter: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
  };

  const useStyles = createStyles(theme => ({
    hiddenMobile: {
      [theme.fn.smallerThan('lg')]: {
        display: 'none',
      },
    },

    hiddenDesktop: {
      [theme.fn.largerThan('lg')]: {
        display: 'none',
      },
    },

    navItems: {
      display: 'flex',
      alignItems: 'center',
    },

    projectHeading: {
      minWidth: '15rem',
      overflow: 'hidden',
      color: '#818181',
      fontSize: '1.7rem',
    },
  }));
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);

  const { classes, cx } = useStyles();

  const { user, isAdmin } = useAuthProvider();

  return (
    <>
      {data && (
        <>
          <MantineHeader height={100} style={headerStyle}>
            <div style={alignCenter}>
              <a href="/app/home">
                <Image src={data.projectConfig.contents.logo} withPlaceholder fit="contain" width={200} />
              </a>
              <Title pl={'lg'} className={cx(classes.projectHeading, classes.hiddenMobile)}>
                IAG Shareholder Class Action
              </Title>
            </div>

            <div style={{ flex: 1 }} />
            <Burger opened={drawerOpened} onClick={toggleDrawer} size="sm" className={classes.hiddenDesktop} />

            <div className={cx(classes.navItems, classes.hiddenMobile)}>
              <Menu
                hidden={!isAdmin}
                placement="start"
                size={'lg'}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                pr={'lg'}
                control={
                  <Button
                    variant="subtle"
                    rightIcon={<FontAwesomeIcon icon={open ? faCaretUp : faCaretDown} />}
                    p={'xs'}
                    size={'md'}
                  >
                    Menu
                  </Button>
                }
              >
                {adminMenu.map((item: { label: string; route: string }) => {
                  return (
                    <Menu.Item component={Link} to={item.route} key={item.route}>
                      {item.label}
                    </Menu.Item>
                  );
                })}
              </Menu>
              <Divider
                hidden={!isAdmin}
                orientation="vertical"
                size={'md'}
                variant="solid"
                sx={{ height: '2.5rem', margin: '1rem' }}
                color="dark"
              />
              <Text color="dimmed" size="xl">
                {user.email}
              </Text>
              <Divider
                orientation="vertical"
                size={'md'}
                variant="solid"
                sx={{ height: '2.5rem', margin: '1rem' }}
                color="dark"
              />
              <Button
                variant="outline"
                styles={{
                  outline: { borderColor: 'gray', color: 'dimgray' },
                }}
                onClick={handleLogout}
              >
                Logout
              </Button>
              <Avatar radius="xl" ml={'xl'} size="lg" styles={{ root: { border: '1px black solid' } }} />
            </div>
          </MantineHeader>
          <Drawer
            opened={drawerOpened}
            onClose={closeDrawer}
            size="100%"
            padding="md"
            sx={{
              backgroundColor: 'rgb(241, 241, 241)',
            }}
            title={data.projectConfig.contents.heading}
            className={classes.hiddenDesktop}
            zIndex={1000000}
          >
            <ScrollArea sx={{ height: '100vh' }} mx="-md">
              <Divider my="sm" />

              <Paper p={'1rem'}>
                <Text>Project Admin Menu</Text>
                {adminMenu.map((item: { label: string; route: string }) => {
                  return (
                    <div key={item.route}>
                      <Anchor href={item.route}> {item.label}</Anchor>
                    </div>
                  );
                })}

                <Text color="dimmed" size="sm">
                  {user.email}
                </Text>
              </Paper>

              <Divider my="sm" />

              <Group position="center" grow pb="xl" px="md">
                <Button
                  variant="outline"
                  styles={{
                    outline: { borderColor: 'gray', color: 'dimgray' },
                  }}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </Group>
            </ScrollArea>
          </Drawer>
        </>
      )}
    </>
  );
}
