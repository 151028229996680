import { LoadingOverlay } from '@mantine/core';
import { AdminSubmissionsPage } from '../../iag/pages/admin-submissions.page';
import { useAuthProvider } from '../../iag/providers/iag-auth.provider';
import { CustomerHome } from './components/customer-home';

export const IagHome = () => {
  const { isAdmin, isLoading } = useAuthProvider();

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  if (isAdmin) {
    return <AdminSubmissionsPage />;
  }
  return <CustomerHome />;
};
