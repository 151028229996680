const NAV = {
  HOME: '/',
  LOGIN: '/login',

  REGISTER: '/register',
  REGISTER_NOTIF: '/register/verify',
  REGISTER_SUCCESS: '/register/success',
  REGISTER_CONFIRM: '/register/:confirmationCode',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_SENT: '/reset-password/sent',
  RESET_PASSWORD_SUCCESS: '/reset-password/success',
  SET_PASSWORD: '/set-password',
  FORM: '/form',

  CUSTOMER_HOME: '/customer-home',

  USER_SUBMISSIONS_LIST: '/me/submissions',
  USER_SUBMISSIONS_VIEW: '/me/submissions/:id',

  PROJECT_PAGE: '/page/:path',

  /** admin routes */
  ADMIN: '/admin',
  ADMIN_SUBMISSION_LIST: '/admin/submissions',
  ADMIN_SUBMISSION_VIEW: '/admin/submissions/:id',
  ADMIN_PROJECT_SETTINGS: '/admin/project-settings',
  ADMIN_USER_LIST: '/admin/users',
};

const IAG_NAV = {
  TOKEN_SIGNIN: '/app/signin-by-token',
  ACCOUNT_VERIFICATION: '/app/verify-account',
  HOME: '/app/home',
  OPT_OUT: '/optout',
};
export { NAV, IAG_NAV };
