import { Container, Image, Text, Title } from '@mantine/core';
import { useGetProjectDetails } from '../pages/login/hooks';

export const Maintenance = () => {
  const { data } = useGetProjectDetails();

  const alignCenter: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '8rem',
  };
  return (
    <>
      <Container>
        <div style={alignCenter}>
          <Image src={data?.projectConfig.contents.logo} withPlaceholder fit="contain" width={600} />
          <br />
          <Title pl={'lg'}>{data?.projectConfig.contents.heading}</Title>
          <br />
          <Text size="xl">We are currently implementing improvements. Won't be too long. </Text>
          <Text size="xl">Please check again tomorrow.</Text>
        </div>
      </Container>
    </>
  );
};
