import { faSpinner, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Container } from '@mantine/core';
import { useEffect } from 'react';
import { useVerifyAccount } from '../register/hooks';

export const AccountVerificationPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  const { mutate, isError, error, isSuccess } = useVerifyAccount();

  useEffect(() => {
    if (token) {
      mutate({
        token: token,
      });
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      window.location.href = '/app/home';
    }
  }, [isSuccess]);

  if (isError) {
    return (
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyItems: 'center',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'center',
          marginTop: '5rem',
        }}
      >
        <Alert
          sx={{
            width: '20rem',
          }}
          variant="filled"
          color="red"
        >
          <p
            style={{
              fontSize: '1.25rem',
            }}
          >
            <FontAwesomeIcon icon={faWarning} />
            &nbsp; {error.error.message}
          </p>
        </Alert>
      </Container>
    );
  }

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginTop: '5rem',
      }}
    >
      <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="5x" />
      <h1>Loading</h1>
    </Container>
  );
};
