import { Container } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { IagHeader } from '../component/Header/IagHeader';
import { useAuthProvider } from './providers/iag-auth.provider';

export const IagAuthLayout = ({ children }: PropsWithChildren) => {
  const { isAuthenticated, isLoading } = useAuthProvider();
  const navigate = useNavigate();

  if (!isAuthenticated && !isLoading) {
    navigate('/register');
  }

  return (
    <>
      <Helmet>
        <title>IAG Shareholder Class Action</title>
      </Helmet>
      <Container size="xl" fluid px={0}>
        <IagHeader />

        <Container size="lg">{children}</Container>
      </Container>
    </>
  );
};
