import { useMutation } from 'react-query';
import { IErrorObject } from '../../services/types';
import { IagRegister } from '../../pages/register/types';
import iagService from '../service/iag.service';
import { showNotification } from '@mantine/notifications';

interface UpdatePayload {
  id: string;
  member: IagRegister;
}
export const useUpdateMember = () => {
  return useMutation<any, IErrorObject, UpdatePayload, any>(
    async (payload: UpdatePayload) => {
      return await iagService.UpdateMember(payload.member, payload.id);
    },
    {
      onSuccess: () => {
        showNotification({
          id: 'update-success',
          title: 'Success',
          message: 'Team updated',
          autoClose: 3000,
          color: 'green',
        });
      },
      onError(error) {
        showNotification({
          id: 'update-errir',
          title: 'Error',
          message: error.error.message,
          autoClose: 3000,
          color: 'red',
        });
      },
    },
  );
};
