import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Container } from '@mantine/core';
import { Helmet } from 'react-helmet';
import { OptOutHeader } from '../../component/Header/OptOutHeader';

export const RegistrationSuccessPage = () => {
  return (
    <>
      <Helmet>
        <title>IAG Shareholder Class Action</title>
      </Helmet>
      <OptOutHeader />
      <Container size="xl" fluid px={0}>
        <Container
          size="lg"
          sx={{
            marginTop: '1rem',
            marginBottom: '10rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Alert
            sx={{
              width: '50rem',
            }}
            variant="filled"
            color="green"
          >
            <p
              style={{
                fontSize: '1rem',
                textAlign: 'center',
              }}
            >
              <FontAwesomeIcon size="4x" icon={faCheckCircle} />
              <br />
              <span style={{ fontSize: '2rem' }}> Email verification sent.</span>
              <br />
              Email verification links expire in one hour unless a new link is generated
              <br />
              (prior links expire immediately).
              <br />
              Please check your junk/spam folder, if you have not received it.
              <br />
              <br />
              <Button component="a" href="/" variant="white">
                Return
              </Button>
            </p>
          </Alert>
        </Container>
      </Container>
    </>
  );
};
