import { useQuery } from 'react-query';
import iagService from '../service/iag.service';
import { AxiosResponse } from 'axios';
import { SubmissionSchema, SubmissionSchemaV2, SubmissionStatus } from '../types';

export interface ISubmission {
  id: string;
  submission: {
    data: SubmissionSchemaV2;
    status: SubmissionStatus;
  };
}
export const useSubmission = (submissionId: string | undefined) => {
  const { data, ...restQuery } = useQuery<AxiosResponse<ISubmission>>({
    queryKey: ['useSubmission', submissionId],
    queryFn: async () => {
      return iagService.GetSubmissionById(submissionId);
    },
    enabled: submissionId !== undefined,
  });

  return {
    ...restQuery,
    data: data?.data,
  };
};
